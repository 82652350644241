import React from "react";
import { graphql } from "gatsby";
// import Img from "gatsby-image";

import { GridGallery } from "../../components/gallery";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import "../../css/plans.css";
// import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import parade2019 from "../../images/parade-2019.jpg";
import { FaTrophy, FaCrown, FaAward, FaMedal, FaStar } from 'react-icons/fa';

class ParadePage extends React.Component {
  render(){
    const {data} = this.props;
    const gallery = data.contentfulGalleryPage;

    return (
      <Layout>
        <SEO title="2019 Parade Home" />
        <div className="container">

          <div className="row">
            <div className="col-sm mt-auto">
              <h1 className="mt-4 mb-0">2019 Parade Home</h1>
            </div>
          </div>

          <Row>
            <Col>

              <h4 className="mt-3">4126 sqft</h4>

              <h5 className="mt-3 mb-3">Awards</h5>
              <div><FaCrown /> Best of Wasatch Overall Winner</div>
              <div><FaTrophy /> Best Master Suite Award</div>
              <div><FaStar /> Best Curb Appeal Award</div>
              <div><FaAward /> Best Special Feature</div>
              <div><FaMedal /> Best Floor Plan Award</div>
            </Col>
            <Col>
              <img src={parade2019} className="img-fluid" alt="Awards"/>
            </Col>
          </Row>

          <Row className="row mt-3 mb-4">
            <Col>
              <GridGallery images={gallery.gallery} gid="galleryGrid"/>
            </Col>
          </Row>


        </div>

      </Layout>
    )
  }
};

export default ParadePage;

export const pageQuery = graphql`
query {
  site {
    siteMetadata {
      title
      author
    }
  }
  contentfulGalleryPage(contentfulid: {eq: 3}) {
      id
      gallery {
        fluid {
          ...GatsbyContentfulFluid
        }
        file {
          url
          fileName
          contentType
        }
      }
    }
  }`
